// axiosApi
import axios from "axios";
import * as Cookies from "js-cookie";

const axiosApi = axios.create({
  baseURL: `https://osama-back.onrender.com/api/v1/`, //http://localhost:8080/ //https://crowded-cod-coveralls.cyclic.app
  headers: {
    "Content-Type": "application/json",
  },
});
axiosApi.CancelToken = axiosApi.CancelToken;
axiosApi.isCancel = axiosApi.isCancel;

/*
 * The interceptor here ensures that we check for the token in local storage every time an axiosApi request is made
 */
axiosApi.interceptors.request.use(
  (config) => {
    NProgress.start();

    let token = null;
    if (Cookies.get("token")) {
      token = Cookies.get("token");
    }
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
axiosApi.interceptors.response.use((response) => {
  NProgress.done();
  return response;
});

export default axiosApi;
